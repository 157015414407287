import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/home';
import SolarClock from './pages/solar-clock';
import UltraVioletPage from './pages/ultra-violet';
import CompassPage from './pages/compass-page';
import './css/App.css';
import CircadianScience from './pages/circadian-science';
import SolarEvents from './pages/solar-events';
import useBrowserInfo from './hooks/use-browser-info';
import { usePage, Pages } from './providers/page-provider';
import SplashPage from './pages/splash-page';

export default function App() {
  return <SplashPage />;
}
