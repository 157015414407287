// Import Luxon (you'll need to install it via npm if you're using Node.js: npm install luxon)
import axios from 'axios';
import logger from './logger';
import { loadUvDataFromCache, saveUvDataToCache } from './local-storage';

const { DateTime } = require('luxon');

const OpenUvAPIKey = 'openuv-1mxfsvrm1zez9ip-io';

// Enum for Fitzpatrick Skin Types
export const SkinType = {
  I: 'I',
  II: 'II',
  III: 'III',
  IV: 'IV',
  V: 'V',
  VI: 'VI',
};

// Function to get MED based on skin type
function getMEDForSkinType(skinType) {
  const MED_VALUES = {
    [SkinType.I]: 200, // Type I
    [SkinType.II]: 250, // Type II
    [SkinType.III]: 300, // Type III
    [SkinType.IV]: 450, // Type IV
    [SkinType.V]: 600, // Type V
    [SkinType.VI]: 800, // Type VI
  };

  return MED_VALUES[skinType] || null;
}

// Function to calculate altitude adjustment factor
function getAltitudeFactor(altitude) {
  // 10% increase in UV for every 1,000 meters
  return 1 + (altitude / 1000) * 0.1;
}

// Function to calculate latitude adjustment factor
function getLatitudeFactor(latitude) {
  if (Math.abs(latitude) <= 30) {
    return 1; // Low latitude
  } else if (Math.abs(latitude) <= 60) {
    return 0.8; // Mid latitude
  } else {
    return 0.6; // High latitude
  }
}

// Function to determine season factor based on date (Luxon DateTime object) and latitude
function getSeasonFactor(latitude, date) {
  const month = date.month; // Luxon provides month directly (1-12)

  if (latitude >= 0) {
    // Northern hemisphere
    if (month >= 6 && month <= 8) {
      return 1; // Summer
    } else if ((month >= 3 && month <= 5) || (month >= 9 && month <= 11)) {
      return 0.8; // Spring/Fall
    } else {
      return 0.5; // Winter
    }
  } else {
    // Southern hemisphere
    if (month === 12 || month === 1 || month === 2) {
      return 1; // Summer in Southern hemisphere
    } else if ((month >= 3 && month <= 5) || (month >= 9 && month <= 11)) {
      return 0.8; // Spring/Fall
    } else {
      return 0.5; // Winter
    }
  }
}

// Function to calculate total adjustment factor
function calculateTotalAdjustmentFactor(altitude, latitude, date) {
  const altitudeFactor = getAltitudeFactor(altitude);
  const latitudeFactor = getLatitudeFactor(latitude);
  const seasonFactor = getSeasonFactor(latitude, date);
  return altitudeFactor * latitudeFactor * seasonFactor;
}

// Main function to calculate safe sun exposure time
export function calculateSafeSunExposureTime(
  uvIndex,
  altitude,
  latitude,
  date,
  skinType,
  sunTimes,
) {
  const MED = getMEDForSkinType(skinType);
  if (!MED) {
    return 'Invalid skin type.';
  }

  const totalAdjustmentFactor = calculateTotalAdjustmentFactor(altitude, latitude, date);
  const safeExposureTime = MED / (uvIndex * totalAdjustmentFactor);
  return Math.round(safeExposureTime);
}

export const UvUpdateInterval = 60 * 1000 * 30;

export const UvDataTypes = {
  CURRENT: 'current',
  FORECAST: 'forecast',
};

export async function fetchUVIndex(latitude, longitude, displayDate) {
  const openUVURL = `https://api.openuv.io/api/v1/uv`;

  try {
    // Attempt to load cached data
    const cachedData = await loadUvDataFromCache(
      latitude,
      longitude,
      displayDate,
      UvDataTypes.CURRENT,
    );

    if (cachedData) {
      console.log('Returning cached current UV data');
      return cachedData;
    }

    // If no valid cache, make API request
    const response = await axios.get(openUVURL, {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': OpenUvAPIKey,
      },
      params: {
        lat: latitude,
        lng: longitude,
      },
    });

    const data = response.data;

    if (data && data.result) {
      const { uv: uvIndex, uv_max: maxUv, uv_max_time: maxUvTime } = data.result;
      console.warn('UV data found', uvIndex, maxUv, maxUvTime);

      // Save the new data to cache
      await saveUvDataToCache(
        latitude,
        longitude,
        { uvIndex, maxUv, maxUvTime },
        displayDate,
        UvDataTypes.CURRENT,
      );

      return { uvIndex, maxUv, maxUvTime };
    } else {
      console.warn('No UV data found for the given location.');
      return null;
    }
  } catch (error) {
    console.error('Error fetching UV data:', error.message);
  }
}

export async function fetchUVForecast(latitude, longitude, displayDate) {
  const openUVForecastURL = `https://api.openuv.io/api/v1/forecast`;

  try {
    // Attempt to load cached data
    const cachedData = await loadUvDataFromCache(
      latitude,
      longitude,
      displayDate,
      UvDataTypes.FORECAST,
    );

    if (cachedData) {
      console.log('Returning cached UV forecast data');
      return cachedData;
    }

    // If no valid cache, make API request
    const response = await axios.get(openUVForecastURL, {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': OpenUvAPIKey,
      },
      params: {
        lat: latitude,
        lng: longitude,
        dt: displayDate.toISO({ includeOffset: false }),
      },
    });

    const data = response.data;

    if (data && data.result && data.result.length > 0) {
      // Assuming 'result' is an array of UV forecasts
      const forecast = data.result.map((item) => ({
        uvIndex: item.uv,
        uvTime: item.uv_time,
      }));

      console.warn('UV forecast found', forecast);

      // Save the new data to cache
      await saveUvDataToCache(
        latitude,
        longitude,
        forecast,
        displayDate,
        UvDataTypes.FORECAST,
      );

      return forecast;
    } else {
      console.warn('No UV forecast data found for the given location.');
      return [];
    }
  } catch (error) {
    console.error('Error fetching UV forecast data:', error);
  }
}
