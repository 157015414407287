import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SunriseSun } from '../icons/sun-svg-icon';
import AppHeader from '../components/app_header';
import { getLayoutType, SunTypes } from '../components/sun_path';
import {
  displayBackgroundGradient,
  useBackground,
  useBackgroundAnimation,
} from '../hooks/use-background-animation';
import { ModalTypes, useModal } from '../providers/modal-provider';

import '../css/Home.css';
import { useSolarClock } from '../providers/solar-clock-provider';
import { useAuth } from '../providers/auth-provider';
import { formatDayTimeAndZone, getDateTime } from '../utils/time';
import useBrowserInfo from '../hooks/use-browser-info';
import { InfoPanelState, useInfoPanel } from '../components/info-panel';
import { getSunType } from '../domain/sun-type';
import { DateTime } from 'luxon';
import { CircadianEventsList } from '../components/circadian_event';
import SunIcon, { isSunIcon } from '../icons/sun_icon';
import { generateSunPath } from '../utils/sun-path';
import MoonPhase from '../icons/moon-svg';
import Navigation from '../components/navigation';
import SiteNav from '../components/site-nav';
import { createSunPathAnimation, waitForRef } from '../utils/sun-animation';
import { getSunTimes } from '../hooks/use-sun-times';
import logger from '../utils/logger';
import { HeaderTime } from '../components/floating-time';
import { useLocation } from 'react-router';
import useFeature, { FeatureFlags } from '../hooks/use-feature';
import LocationPage from './location-page';
import { OrientationProvider } from '../providers/orientation-provider';

const now = DateTime.now();

const sunAnimationStartDate = DateTime.fromObject({
  year: now.year,
  month: now.month,
  day: now.day,
  hour: 1,
  minute: 12,
});

const SunriseOffset = -258;
const UVAOffset = 0;
const UVBOffset = 125;
const SolarNoonUVBOffset = 275;
const SolarNoonUVAOffset = 225;
const SolarNoonNoUVOffset = 175;

function calculatePercentageOffset(startTime, endTime, currentTime) {
  const timeSinceStart = currentTime.diff(startTime, 'minutes');
  const timeToEnd = endTime.diff(startTime, 'minutes');
  return timeSinceStart / timeToEnd;
}

function calculateOffset(startOffset, endOffset, percentage) {
  return startOffset + (endOffset - startOffset) * percentage;
}

function calculateSunOffset(sunTimes, currentTime, imageHeight = 268) {
  const { sunrise, uvaRise, uvbRise, solarNoon, uvbSet, uvaSet, sunset } = sunTimes;

  if (currentTime <= sunrise || currentTime >= sunset) {
    // Before sunrise or after sunset, the image is offscreen at the bottom
    return SunriseOffset;
  }

  if (uvaRise && !uvbRise) {
    if (currentTime <= uvaRise) {
      // Moving up from sunrise to UVA rise
      const percentage = calculatePercentageOffset(sunrise, uvaRise, currentTime);
      return calculateOffset(SunriseOffset, UVAOffset, percentage);
    } else if (currentTime <= solarNoon) {
      // Moving up from UVA rise to solar noon
      const percentage = calculatePercentageOffset(uvaRise, solarNoon, currentTime);
      return calculateOffset(UVAOffset, SolarNoonUVAOffset, percentage);
    } else if (currentTime <= uvaSet) {
      // Moving down from solar noon to UVA set
      const percentage = calculatePercentageOffset(solarNoon, uvaSet, currentTime);
      return calculateOffset(SolarNoonUVAOffset, UVAOffset, percentage);
    } else {
      // Moving down from UVA set to sunset
      const percentage = calculatePercentageOffset(uvaSet, sunset, currentTime);
      return calculateOffset(UVAOffset, SunriseOffset, percentage);
    }
  } else if (uvbRise) {
    if (currentTime <= uvaRise) {
      // Moving up from sunrise to UVA rise
      const percentage = calculatePercentageOffset(sunrise, uvaRise, currentTime);
      return calculateOffset(SunriseOffset, UVAOffset, percentage);
    } else if (currentTime <= uvbRise) {
      // Moving up from UVA rise to UVB rise
      const percentage = calculatePercentageOffset(uvaRise, uvbRise, currentTime);
      return calculateOffset(UVAOffset, UVBOffset, percentage);
    } else if (currentTime <= solarNoon) {
      // Moving up from UVB rise to solar noon
      const percentage = calculatePercentageOffset(uvbRise, solarNoon, currentTime);
      return calculateOffset(UVBOffset, SolarNoonUVBOffset, percentage);
    } else if (currentTime <= uvbSet) {
      // Moving down from solar noon to UVB set
      const percentage = calculatePercentageOffset(solarNoon, uvbSet, currentTime);
      return calculateOffset(SolarNoonUVBOffset, UVBOffset, percentage);
    } else if (currentTime <= uvaSet) {
      // Moving down from UVB set to UVA set
      const percentage = calculatePercentageOffset(uvbSet, uvaSet, currentTime);
      return calculateOffset(UVBOffset, UVAOffset, percentage);
    } else {
      // Moving down from UVA set to sunset
      const percentage = calculatePercentageOffset(uvaSet, sunset, currentTime);
      return calculateOffset(UVAOffset, SunriseOffset, percentage);
    }
  } else {
    if (currentTime <= solarNoon) {
      // Moving up from sunrise to solar noon
      const percentage = calculatePercentageOffset(sunrise, solarNoon, currentTime);
      return calculateOffset(SunriseOffset, SolarNoonNoUVOffset, percentage);
    } else {
      // Moving down from solar noon to sunset
      const percentage = calculatePercentageOffset(solarNoon, sunset, currentTime);
      return calculateOffset(SolarNoonNoUVOffset, SunriseOffset, percentage);
    }
  }
}

const MoonPeakOffset = 225;
const MoonRiseOffset = -215;
const MoonDiff = MoonPeakOffset - MoonRiseOffset;

function calculateMoonOffset(sunTimes, currentTime, imageHeight = 268) {
  const { sunset, nadir, sunrise, dusk, dawn } = sunTimes;

  if (!(currentTime >= sunset || currentTime <= sunrise)) {
    // Before sunset or after sunrise, the image is offscreen at the bottom
    return MoonRiseOffset;
  }

  const tomorrowSolarNadirTime = nadir.plus({ days: 1 });
  const timeSinceSunset = currentTime.diff(sunset, 'minutes').minutes;
  const timeToNadir = tomorrowSolarNadirTime.diff(sunset, 'minutes').minutes;
  const timeSinceNadir = currentTime.diff(nadir, 'minutes').minutes;
  const timeFromNadirToSunrise = sunrise.diff(nadir, 'minutes').minutes;

  let bottom;
  if (currentTime > sunset) {
    // Moving up from sunset to solar nadir
    bottom = MoonRiseOffset + MoonDiff * (timeSinceSunset / timeToNadir);
  } else {
    // Moving down from solar nadir to sunrise
    bottom = MoonPeakOffset - MoonDiff * (timeSinceNadir / timeFromNadirToSunrise);
  }

  return bottom;
}

const CelestialIcon = ({ sunTimes, sunElevationTime, moonPhase, activeEvent }) => {
  const { currentProgress, sunType, nextSunType } = useMemo(
    () => generateSunPath(sunTimes, sunElevationTime, 75),
    [sunTimes, sunElevationTime],
  );

  //  // logger.log('Using sunType', sunType, ' for sun time', formatDayTimeAndZone(sunElevationTime))
  return isSunIcon(sunType) ? (
    <SunIcon
      sunType={sunType}
      nextSunType={nextSunType}
      progress={currentProgress}
      activeEvent={activeEvent}
      isMobile={false}
      radius={75}
    />
  ) : (
    <MoonPhase
      radius={100}
      phase={moonPhase}
      activeEvent={activeEvent}
      isMobile={false}
    />
  );
};

function SolarEvents({ onLoad, isLoaded }) {
  const { user } = useAuth();
  const isFeatureActive = useFeature();
  const { showInfoPanelPreview, infoPanel } = useInfoPanel();
  const [sunriseStyles, setSunriseStyles] = useState(undefined);
  const [sunriseClasses, setSunriseClasses] = useState(
    isLoaded ? 'solar-clock-container' : 'solar-clock-container solar-clock-animation',
  );
  const {
    timezone,
    coordinates,
    isLocationLoading,
    activeEvent,
    activateEvent,
    today,
    sunTimes,
    displayDate,
    isNight,
    moonPhase,
    isClockDataLoaded,
  } = useSolarClock();
  // From Solar Clock

  const [sunElevationTime, setSunElevationTime] = useState(getDateTime(today));
  const [isSunSyncAnimationRunning, setIsSunSyncAnimationRunning] = useState(false);
  const [animatedSunTime, setAnimatedSunTime] = useState(sunAnimationStartDate);
  const [isAnimationDarkMode, setIsAnimationDarkMode] = useState(false);
  const stateRef = useRef();

  useEffect(() => {
    if (isClockDataLoaded && !stateRef.current) {
      // logger.log('Clock Date Loaded\n\n');
      stateRef.current = { currentTime: displayDate, coords: coordinates, tz: timezone };
    }
  }, [isClockDataLoaded, displayDate, coordinates, timezone]);

  const handleOnFrame = (currentDateTime, isDarkTheme) => {
    setAnimatedSunTime(currentDateTime); // Update sun elevation time for each frame
    setIsAnimationDarkMode(isDarkTheme);
  };

  const isDarkTheme = useMemo(() => {
    if (isSunSyncAnimationRunning) {
      return isAnimationDarkMode;
    } else {
      return isNight(sunElevationTime);
    }
  }, [sunElevationTime, isSunSyncAnimationRunning, isAnimationDarkMode]);

  const onHoverEvent = (eventLabel, _eventDate) => {
    activateEvent(eventLabel);
    showInfoPanelPreview(eventLabel);
  };

  useEffect(() => {
    setSunElevationTime(displayDate);
  }, [displayDate]);

  const onAnimationEnd = async () => {
    // logger.log('Background Animation Ended\n\n');
    setSunriseClasses('solar-clock-container');

    await waitForRef(stateRef);
    // logger.log('Loaded local date', formatDayTimeAndZone(stateRef.current.currentTime));
    // logger.log('Current time loaded\n\n');

    let sunSyncAnimationTimer;
    const animate = async () => {
      // logger.log('Sun Sync Animation Started\n\n');

      const { currentTime, coords, tz } = stateRef.current;
      const animatedSunTimes = getSunTimes(coords, currentTime, tz);
      const { sunrise, solarNoon, sunset } = animatedSunTimes;
      setAnimatedSunTime(sunrise);
      setIsSunSyncAnimationRunning(true);

      const sunSyncAnimation = createSunSyncAnimation(
        currentTime,
        sunrise,
        solarNoon,
        sunset,
      );
      // logger.log('Starting animation');
      sunSyncAnimationTimer = await sunSyncAnimation.start();
      setIsSunSyncAnimationRunning(false);

      if (onLoad) {
        onLoad();
      }
    };

    void animate();
  };

  const { startBackgroundAnimation, isBackgroundAnimationRunning } =
    useBackgroundAnimation(onAnimationEnd, [
      {
        start: SunTypes.Twilight,
        stop: SunTypes.SolarNoon,
        direction: 'forward',
        delay: 0,
        duration: 1.5,
      },
      {
        start: SunTypes.SolarNoon,
        stop: SunTypes.Red,
        direction: 'reverse',
        delay: 0,
        duration: 1.5,
      },
    ]);

  const isAnimationRunning = useMemo(
    () => isSunSyncAnimationRunning || isBackgroundAnimationRunning,
    [isSunSyncAnimationRunning, isBackgroundAnimationRunning],
  );

  const isAppLoaded = !isLocationLoading && !isAnimationRunning && isLoaded;

  const isCurrentTimeLoading = useMemo(
    () => stateRef.current === undefined,
    [stateRef.current],
  );
  const displaySunTime = useMemo(() => {
    if (isLoaded) {
      // logger.log('displaySunTime: isLoaded', formatDayTimeAndZone(sunElevationTime));
      return sunElevationTime;
    }
    // // logger.log(
    //   'displaySunTime: isSunSyncAnimationRunning',
    //   formatDayTimeAndZone(animatedSunTime),
    // );
    return animatedSunTime;
  }, [
    isSunSyncAnimationRunning,
    animatedSunTime,
    sunElevationTime,
    isCurrentTimeLoading,
  ]);

  const sunPositionOffset = useMemo(
    () => calculateSunOffset(sunTimes, displaySunTime),
    [displaySunTime],
  );

  const moonPositionOffset = useMemo(
    () => calculateMoonOffset(sunTimes, displaySunTime),
    [displaySunTime],
  );

  const sunType = useMemo(() => {
    if (isSunSyncAnimationRunning) {
      // // logger.log(
      //   'sunTypeMemo: isSunSyncAnimationRunning',
      //   formatDayTimeAndZone(displaySunTime),
      //   ',',
      //   getSunType(sunTimes, displaySunTime),
      // );
      return getSunType(sunTimes, displaySunTime);
    } else if (isLoaded) {
      // // logger.log(
      //   'sunTypeMemo: isLoaded',
      //   formatDayTimeAndZone(displaySunTime),
      //   ',',
      //   getSunType(sunTimes, displaySunTime),
      // );
      return getSunType(sunTimes, displaySunTime);
    } else {
      // logger.log('sunTypeMemo: Return hardcoded red sun');
      return SunTypes.Red;
    }
  }, [sunTimes, displaySunTime, isSunSyncAnimationRunning, isLoaded]);

  useEffect(() => {
    if (isBackgroundAnimationRunning) {
      // Animation handles gradients
      return;
    }
    if (isSunSyncAnimationRunning) {
      // // logger.log(
      //   'isSunSyncAnimationRunning: displayBackgroundGradient(sunType)',
      //   sunType,
      // );
      displayBackgroundGradient(sunType);
    } else if (isLoaded) {
      // logger.log('isLoaded: displayBackgroundGradient(sunType)', sunType);
      displayBackgroundGradient(sunType);
    } else {
      // logger.log('isCurrentTimeLoading. Hardcoded Red');
      displayBackgroundGradient(SunTypes.Red);
    }
  }, [sunType, isBackgroundAnimationRunning, isSunSyncAnimationRunning, isLoaded]);

  useEffect(() => {
    // logger.log('SunType', sunType);
  }, [sunType]);

  useEffect(() => {
    if (!isLocationLoading) {
      if (isSunIcon(sunType)) {
        setSunriseStyles({ bottom: sunPositionOffset + 'px' });
      } else {
        setSunriseStyles({ bottom: moonPositionOffset + 'px' });
      }
    }
  }, [isLocationLoading, sunPositionOffset, moonPositionOffset]);

  const createSunSyncAnimation = (currentTime, sunrise, solarNoon, sunset) => {
    // // logger.log(
    //   'Animating from ',
    //   formatDayTimeAndZone(sunrise),
    //   'to',
    //   formatDayTimeAndZone(currentTime),
    // );
    const startTime = currentTime > solarNoon ? sunset : sunrise;
    return createSunPathAnimation(
      startTime,
      currentTime,
      sunrise,
      sunset,
      handleOnFrame,
      1,
      700,
      500,
    );
  };

  useEffect(() => {
    if (isLoaded) {
      return;
    }

    if (!isFeatureActive(FeatureFlags.AnimateSolarClock)) {
      onLoad();
      return;
    }

    if (!isBackgroundAnimationRunning && !isLoaded) {
      void startBackgroundAnimation();
    }
  }, []);

  return (
    <div className="App">
      <AppHeader darkTheme={isDarkTheme} sunType={sunType} isLoaded={isLoaded} />
      {isLoaded && (
        <SiteNav darkTheme={isDarkTheme} isLoaded={!isAppLoaded} sunType={sunType} />
      )}
      <Navigation
        darkTheme={isDarkTheme}
        isLocationLoading={!isAppLoaded}
        sunType={sunType}
      />
      <div className="content-container">
        <div className="circadian-events-container">
          {infoPanel.state !== InfoPanelState.Full && isAppLoaded && (
            <CircadianEventsList
              sunTimes={sunTimes}
              timezone={timezone}
              onHoverEvent={onHoverEvent}
              sunElevationTime={sunElevationTime}
              activeEvent={activeEvent}
              showTwilight={true}
              darkTheme={isDarkTheme}
            />
          )}
        </div>
        <div className={sunriseClasses} style={sunriseStyles}>
          {isBackgroundAnimationRunning || isCurrentTimeLoading ? (
            <SunriseSun radius={75} />
          ) : (
            <CelestialIcon
              sunTimes={sunTimes}
              sunElevationTime={displaySunTime}
              activeEvent={activeEvent}
              moonPhase={moonPhase}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default function SolarEventsPageWrapper({ onLoad, isLoaded }) {
  return (
    <LocationPage>
      <SolarEvents onLoad={onLoad} isLoaded={isLoaded} />;
    </LocationPage>
  );
}
