import React from 'react';
import { SunriseSun } from '../icons/sun-svg-icon';

import '../css/Splash.css';

function SplashPage() {
  const headerColor = 'dark-mode-text-color';
  const headerClass = `${headerColor} header-top-base header-top-centered`;
  const titleClass = 'title-dark';

  function goToApp() {
    window.location.href = 'https://app.sunlightislife.com/';
  }

  return (
    <div className="App">
      <div className={`header-top ${headerClass}`}>
        <div className={titleClass}>
          <h1>Sunlight is Life</h1>
        </div>
      </div>
      <div className="content-container">
        <div className="pitch-container">
          Where the emerging sciences of Circadian and Quantum Biology align with
          time-honored traditions connecting us to the sun, alongside cutting-edge
          techniques in light therapy and bioharmonization—guiding you to transform your
          health.
        </div>
        <div className="sunrise-container sunrise-animation" onClick={goToApp}>
          <SunriseSun radius={75} />
        </div>
        <div className="button-container">
          <button className="gradient-button button-large" onClick={goToApp}>
            Go to App
          </button>
        </div>
      </div>
    </div>
  );
}

export default SplashPage;
